import React, { FC, useEffect, useContext, useState } from "react";
import { Route, Switch, useHistory } from "react-router";
import { animateScroll } from "react-scroll";
import AOS from 'aos';

import { LangContext } from "../../providers/LangProvider";

import Home from "../../pages/Home";
import Interna from "../../pages/Interna";
import Privacidade from "../../pages/Privacidade";
import Produtos from "../../pages/3R-Produtos";
import Agendamentos from "../../pages/3R-Agendamentos";
import InternaTransparencia from "../../pages/3R-Transparencia";

import { MainDiv } from "./styles";
import 'aos/dist/aos.css';

const Main: FC<any> = () => {
  const { lang } = useContext(LangContext);
  const [animate, setAnimate] = useState<boolean | any>(null);
  const history = useHistory();

  useEffect(() => {
    animateScroll.scrollTo(0);
    setAnimate(true);
    AOS.init({ duration: 1000 });
    setTimeout(() => setAnimate(false), 2000);
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/3r-produtos") {
      window.location.href = "https://produtos.bravaenergia.com";
    }
  }, []);

  return (
    <MainDiv>
      <Switch>
        <Route path={'/'} exact><Home lang={lang} animate={animate} /></Route>
        <Route path={'/portal-privacidade'}><Privacidade lang={lang} animate={animate} /></Route>
        <Route path={'/agendamento-de-visita'}><Agendamentos lang={lang} animate={animate} /></Route>
        <Route path={'/relatorio-de-transparencia'}><InternaTransparencia lang={lang} animate={animate} /></Route>
      </Switch>
    </MainDiv>
  );
};

export default Main;
